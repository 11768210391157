<template>
  <div style="background-color: white">
    <div class="login-form-wrap sctp-container sctp-mar-b15">
      <div class="sctp-pad-tb10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>用户中心</el-breadcrumb-item>
          <el-breadcrumb-item>邮箱审核</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="marquee" style="position: absolute; width: 100%; height: 500px;z-index: 0;">
        <el-carousel height="500px">
          <el-carousel-item v-for="(item) in marquee" :key="item">
            <img alt class="background" style="width: 100%;height: 100%;object-fit: cover;"
                 :src="item"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="flex flex-sb" style="z-index: 99;margin-top: 20px">
        <div class="flex flex-sb flex-col" style="height: 200px">
          <span class="audiomeno">尊敬的用户：{{user.usernice}}</span>
          <span class="audiomeno">您的QQ{{ msg }}</span>
          <span class="audiomeno">感谢您的支持！！！</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {common} from '@/apis/index'

const config = require('../../resources/js/config')
require('@ASSET/libs/jigsaw/jigsaw.min')
export default {
  name: 'Login',
  components: {
    simpDragCaptcha: () => import('../../components/common/SimpDragCaptcha')
  },
  data() {
    return {
      showLoginHints: false,
      loginPageAdvs: null,
      currentTime: new Date().getTime(),
      loginLock: true,
      loginType: 'first',
      showCaptcha: false,
      sendSmsCaptchaCodeCountDown: -1,
      marquee: [],
      msg: null
    }
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
    this.getTopMarquee()
    this.QQAudit()
  },
  methods: {
    getTopMarquee() {
      // common.loadImage({
      //   type: 'loginBanner '
      // }).then(res => {
      //   this.marquee = res.data.map(item => item.imgurl)
      // });
      common.loadImage({
        type: 'LoginPageAdvImgUrl'
      }).then(res => {
        let {data} = res;
        if (data && data.length > 0) {
          this.loginPageAdvs = data.map(item => item.imgurl)[0]
        }
      })
    }, QQAudit() {
      this.$request.post({
        reqcode: '1237',
        reqdesc: 'QQ验证',
        param: this.$route.query.param,
      }).then(res => {
        this.msg = res.retDesc
      });
    }
  }
}
</script>

<style scoped>
.login-form-wrap {
  min-height: 500px;
  position: relative;
  background-color: white;
}

.login-form-wrap .login-form {
  width: 325px;
  border-radius: 3px;
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

.login-form .el-tabs__content {
  min-height: 300px;
}

.login-form .el-form {
  padding-top: 7px;
}

.login-form .el-form .el-button {
  display: block;
  width: 100%;
}

.qr-code-login-tab {
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.qr-code-login-tab > span {
  position: relative;
  top: -43px;
  padding: 2px 5px;
  border: 1px solid #f90;
  border-radius: 3px;
  color: orange;
}

.qr-code-login-tab > span:after {
  content: '';
  position: absolute;
  border: 1px solid orange;
  width: 6px;
  height: 6px;
  right: -4px;
  bottom: 7px;
  transform: rotate(45deg);
  background-color: #FFF;
  border-left-color: #FFF;
  border-bottom-color: #FFF;
}

.captcha {
  position: absolute !important;
  bottom: 58px;
  background-color: #FFF;
  box-shadow: 0 0 10px 1px #ccc;
  z-index: 1;
}

.space-between {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.login-bottom {
  font-size: 12px;
  background-color: #e8e8ee;
  width: calc(100% + 60px);
  position: relative;
  margin-top: -20px;
  left: -30px;
  right: -30px;
  bottom: -20px;
  padding: 12px 50px;
}

.audiomeno {
  font-size: 18px;
  color: #ff6600;
}
</style>
